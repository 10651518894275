import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import HeroSecondaryLanding from '../components/ui-kit/heroSecondaryLanding'
import { BREAKPOINTS, LAYOUT } from '../styles/cssVariables'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import Quote from '../components/quote'
import FeatureList from '../components/featureList'
import LogoGrid from '../components/logoGrid'
import Hero from '../components/hero'



const QuoteContainer = styled(Container)`
  
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
`


const FeatureListWrapper = styled.section`

    margin-bottom: 200px;
    @media (max-width: ${BREAKPOINTS.med}px) {
      margin-bottom: 175px;
    }
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      margin-bottom: 150px;
    }
    @media (max-width: ${BREAKPOINTS.sml}px) {
      margin-bottom: 100px;
    }

`
const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
`

const LogoListWrapper = styled.section`
  padding-bottom: 250px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    // padding-bottom: 200px;
   
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    // padding-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 150px;
  }
`

const LogoListContainer = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`
const SectionContainer = styled.section`
`

const BrandPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query BrandQuery {
      contentfulPageSupport {
        seoTitle
        seoDescription
        openGraphImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        hero {
          heading
          subheading
          ctaText
          ctaDestination
          
        }
        heroSecondaryHeading
        heroSecondaryBody {
          heroSecondaryBody
        }
        firstQuote {
          introText {
            introText
          }
          layout
          quote {
            quote
          }
          ctaText
          ctaDestination
        }
        secondQuote {
          introText {
            introText
          }
          layout
          quote {
            quote
          }
          ctaText
          ctaDestination
        }
        firstFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        secondFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        thirdFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        fourthFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        
      } 
      contentfulSite {
        
        supporters {
          title
          logos {
            id
            title
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
          ctaText
          ctaDestination
        }
        
      } 

    }
  `)
  const openGraphImage = data.contentfulPageSupport.openGraphImage

  const heroHeading = data.contentfulPageSupport.hero.heading
  const heroSubheading = data.contentfulPageSupport.hero.subheading
  const heroCtaText = data.contentfulPageSupport.hero.ctaText
  const heroCtaDestination = data.contentfulPageSupport.hero.ctaDestination
  const seoTitle = data?.contentfulPageSupport?.seoTitle
  const seoDescription = data?.contentfulPageSupport?.seoDescription
  const heroSecondaryHeading = data.contentfulPageSupport.heroSecondaryHeading
  const heroSecondaryBody = data.contentfulPageSupport.heroSecondaryBody.heroSecondaryBody

  const firstQuoteIntroText = data.contentfulPageSupport.firstQuote?.introText
  ? data.contentfulPageSupport.firstQuote?.introText.introText
  : undefined
  const firstQuoteLayout = data.contentfulPageSupport.firstQuote.layout
  const firstQuoteText = data.contentfulPageSupport.firstQuote.quote.quote
  const firstQuoteCtaText = data.contentfulPageSupport.firstQuote.ctaText
  const firstQuoteCtaDestination =
    data.contentfulPageSupport.firstQuote.ctaDestination
  
    const secondQuoteIntroText = data.contentfulPageSupport.secondQuote?.introText
    ? data.contentfulPageSupport.secondQuote?.introText.introText
    : undefined
  const secondQuoteLayout = data.contentfulPageSupport.secondQuote.layout
  const secondQuoteText = data.contentfulPageSupport.secondQuote.quote.quote
  const secondQuoteCtaText = data.contentfulPageSupport.secondQuote.ctaText
  const secondQuoteCtaDestination =
    data.contentfulPageSupport.secondQuote.ctaDestination

  const firstFeatureSectionCards = data.contentfulPageSupport.firstFeatureSection.featureCards
  const firstFeatureSectionTitle = data.contentfulPageSupport.firstFeatureSection.title
  const firstFeatureSectionCtaText = data.contentfulPageSupport.firstFeatureSection.ctaText
  const firstFeatureSectionCtaDestination = data.contentfulPageSupport.firstFeatureSection.ctaDestination
  const firstFeatureSectionImage = data.contentfulPageSupport.firstFeatureSection.image
  const firstFeatureSectionLayout = data.contentfulPageSupport.firstFeatureSection.layout


  const secondFeatureSectionLayout =
    data.contentfulPageSupport.secondFeatureSection.layout
  const secondFeatureSectionImage =
    data.contentfulPageSupport.secondFeatureSection.image
  const secondFeatureSectionTitle =
    data.contentfulPageSupport.secondFeatureSection.title
  const secondFeatureSectionCards =
    data.contentfulPageSupport.secondFeatureSection.featureCards
  const secondFeatureSectionCtaText =
    data.contentfulPageSupport.secondFeatureSection.ctaText
  const secondFeatureSectionCtaDestination =
    data.contentfulPageSupport.secondFeatureSection.ctaDestination

    const thirdFeatureSectionLayout =
    data.contentfulPageSupport.thirdFeatureSection.layout
  const thirdFeatureSectionImage =
    data.contentfulPageSupport.thirdFeatureSection.image
  const thirdFeatureSectionTitle =
    data.contentfulPageSupport.thirdFeatureSection.title
  const thirdFeatureSectionCards =
    data.contentfulPageSupport.thirdFeatureSection.featureCards
  const thirdFeatureSectionCtaText =
    data.contentfulPageSupport.thirdFeatureSection.ctaText
  const thirdFeatureSectionCtaDestination =
    data.contentfulPageSupport.thirdFeatureSection.ctaDestination
  
    const fourthFeatureSectionLayout =
    data.contentfulPageSupport.fourthFeatureSection.layout
  const fourthFeatureSectionImage =
    data.contentfulPageSupport.fourthFeatureSection.image
  const fourthFeatureSectionTitle =
    data.contentfulPageSupport.fourthFeatureSection.title
  const fourthFeatureSectionCards =
    data.contentfulPageSupport.fourthFeatureSection.featureCards
  const fourthFeatureSectionCtaText =
    data.contentfulPageSupport.fourthFeatureSection.ctaText
  const fourthFeatureSectionCtaDestination =
    data.contentfulPageSupport.fourthFeatureSection.ctaDestination

    const partners = data.contentfulSite.supporters.logos
    const partnersTitle = data.contentfulSite.supporters.title
    const partnersCtaText = data.contentfulSite.supporters.ctaText
    const partnersCtaDestination = data.contentfulSite.supporters.ctaDestination

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.sources[0].srcSet}     
      />

      <Hero
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />

      <HeroSecondaryLanding title={heroSecondaryHeading} body={heroSecondaryBody} />

      <FeatureListWrapper>
        <Container width={12}>
          <FeatureListTitle>
              {firstFeatureSectionTitle}
          </FeatureListTitle>
            <FeatureList
              accentColor="var(--color-violet)"
              ctaText={firstFeatureSectionCtaText}
              ctaDestination={firstFeatureSectionCtaDestination}
              featureCardArr={firstFeatureSectionCards.map((card) => [
                card.heading,
                card.body ? card.body.body : undefined,
              ])}
              image={firstFeatureSectionImage}
              layout={firstFeatureSectionLayout}
              offsetImage={true}
            />
        </Container>
      </FeatureListWrapper>

     <SectionContainer>
        <QuoteContainer width={12}>
          <Quote
            ctaDestination={firstQuoteCtaDestination}
            ctaText={firstQuoteCtaText}
            introText={firstQuoteIntroText}
            layout={firstQuoteLayout}
            quote={firstQuoteText}
          />
        </QuoteContainer>
      </SectionContainer>

      <FeatureListWrapper>
        <Container width={12}>
          <FeatureListTitle>
              {secondFeatureSectionTitle}
          </FeatureListTitle>
            <FeatureList
              accentColor="var(--color-violet)"
              ctaText={secondFeatureSectionCtaText}
              ctaDestination={secondFeatureSectionCtaDestination}
              featureCardArr={secondFeatureSectionCards.map((card) => [
                card.heading,
                card.body ? card.body.body : undefined,
              ])}
              image={secondFeatureSectionImage}
              layout={secondFeatureSectionLayout}
              offsetImage={true}
            />
        </Container>
      </FeatureListWrapper>
      
      <SectionContainer>
        <QuoteContainer width={12}>
          <Quote
            ctaDestination={secondQuoteCtaDestination}
            ctaText={secondQuoteCtaText}
            introText={secondQuoteIntroText}
            layout={secondQuoteLayout}
            quote={secondQuoteText}
          />
        </QuoteContainer>
      </SectionContainer>

      <FeatureListWrapper>
        <Container width={12}>
        <FeatureListTitle>
            {thirdFeatureSectionTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={thirdFeatureSectionCtaText}
            ctaDestination={thirdFeatureSectionCtaDestination}
            featureCardArr={thirdFeatureSectionCards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}
            image={thirdFeatureSectionImage}
            layout={thirdFeatureSectionLayout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>

      <FeatureListWrapper>
        <Container width={12}>
        <FeatureListTitle>
            {fourthFeatureSectionTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={fourthFeatureSectionCtaText}
            ctaDestination={
              fourthFeatureSectionCtaDestination
            }
            featureCardArr={fourthFeatureSectionCards.map(
              (card) => [card.heading, card.body ? card.body.body : undefined]
            )}
            image={fourthFeatureSectionImage}
            layout={fourthFeatureSectionLayout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>
      <LogoListWrapper>
          <LogoListContainer>
            <LogoGrid
              ctaDestination={partnersCtaDestination}
              ctaText={partnersCtaText}
              logos={partners}
              title={partnersTitle}
            />
          </LogoListContainer>
      </LogoListWrapper>
    </Layout>
  )
}

export default BrandPage
